<template>
  <div class="macroPrudentialStrategys">
    <div class="macroPrudentialStrategys-wrapper">
      <div class="macroPrudentialStrategys-content">
        <div>
          <h2>MACRO-PRUDENTIAL STRATEGY</h2>
          <div class="block">
            <p class="text">
              The macro-prudential policy is dedicated to the systemic risk
              analysis and assessment and includes the implementation of
              measures, mainly prudential measures, for its mitigation. The
              macro-prudential policy contributes to safeguarding financial
              stability in two aspects:
            </p>
            <p class="text">
              1) preventing the accumulation of risks in the financial
              system;<br />
              2) strengthening the resilience of the financial system.
            </p>
            <p class="text">
              The main objective of the macro-prudential strategy is to offer an
              overall operational framework for the implementation of the
              macro-prudential policy. This strategy aims to:
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.macroPrudentialStrategys-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 50px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.macroPrudentialStrategys-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
.macroPrudentialStrategys-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
</style>
