<template>
  <div class="main-wrapper">
    <Exchange />
    <FinancialStability />
    <FinancialStabilityBaner />
    <BenefitsFromFinancialStability />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import FinancialStability from "./financialStability";
import FinancialStabilityBaner from "./financialStabilityBaner";
import BenefitsFromFinancialStability from "./benefitsFromFinancialStability";


export default {
  components: {
    Exchange,
    FinancialStability,
    FinancialStabilityBaner,
    BenefitsFromFinancialStability,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
