<template>
  <div class="benefitsFromFinancialStability">
    <div class="benefitsFromFinancialStability-wrapper">
      <div class="benefitsFromFinancialStability-content">
        <div>
          <h2>WHO BENEFITS FROM FINANCIAL STABILITY?</h2>
          <div class="block">
            <p class="text">
              Financial stability serves the economy and economic agents; more
              specifically the following benefit:
            </p>
            <ul class="text">
              <li>
                households, enterprises, other private and public entities;
              </li>
              <li>financial systems;</li>
              <li>markets;</li>
              <li>public authorities.</li>
            </ul>
            <p class="text">
              When there is financial stability, economic growth is more sound
              and stable because the savings and investments of the private and
              public economic agents are brought closer to the expectations and
              contractual terms, the markets function better and help to realize
              the financial need of economic entities more efficiently; there is
              higher resilience against financial stress situations, the
              financial crises are avoided and taxpayers do not pay for their
              costs, economic policies of the central bank and other public
              authorities are more effective.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.benefitsFromFinancialStability-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  margin-bottom: 120px;
  @media (max-width: 1100px) {
    padding-top: 0px;
    margin-bottom: 0px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.benefitsFromFinancialStability-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 30px;
  margin-bottom: 22px;
  li {
    position: relative;
    margin-bottom: 10px;
    &::before {
      content: "";
      position: absolute;
      top: 6px;
      left: -26px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #006942;
    }
  }
}
</style>
