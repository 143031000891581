<template>
  <div class="macroPrudentialPolicies">
    <div class="macroPrudentialPolicies-wrapper">
      <h1>MACRO-PRUDENTIAL POLICIES</h1>
      <div class="macroPrudentialPolicies-content">
        <div class="sectionOne">
          <div class="block-green">
            <h2 class="title-white">
              WHAT ARE MACRO-PRUDENTIAL POLICIES AND WHICH INSTITUTION APPLIES
              THEM?
            </h2>
            <p class="text-white">
              The macro-prudential policy is dedicated to the analysis and
              assessment of the systemic risk and includes the implementation of
              measures for its prevention and mitigation. The macro-prudential
              policy is drafted and implemented by the macro-prudential
              authority of the country, which, depending on the structure of the
              financial system and the form of its supervision, can be the
              central bank or an inter-institutional forum representing several
              public authorities.
            </p>
          </div>
          <div class="block-grey">
            <h2 class="title-black">
              WHAT IS THE PURPOSE OF MACRO-PRUDENTIAL POLICIES?
            </h2>
            <p class="text-black">
              In practice, the purpose of macro-prudential measures is to
              prevent the transformation of a limited risk into a systemic risk,
              as well as to safeguard the rest of the system from the negative
              consequences in case of the failure of systemically important
              institutions or banks. Macro-prudential policies aim to:
            </p>
            <ul class="text-black">
              <li>
                identify, monitor, and assess the type and size of risks that
                threaten financial stability;
              </li>
              <li>
                strengthen financial system capacities to avoid or cope with
                these risks with as little loss as possible.
              </li>
            </ul>
          </div>
        </div>
        <div class="sectionTwo">
          <div>
            <img
              src="..\..\..\assets\macroPrudentialPolicies\macroPrudentialPoliciesBg.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.macroPrudentialPolicies-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 120px;
  @media (max-width: 1180px) {
    padding-top: 35px;
    max-width: 1024px;
    padding-bottom: 35px;
  }
}
h1 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  color: #006942;
  margin-left: 270px;
  margin-bottom: 80px;
  position: relative;
  @media (max-width: 1024px) {
    padding-left: 20px;
    margin: 0;
    padding-bottom: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 17px;
    right: 102%;
    width: 100%;
    height: 2px;
    background: #006942;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.sectionOne {
  width: 100%;
}
.sectionTwo {
  img {
    @media (max-width: 510px) {
      width: 100%;
    }
  }
  width: 50%;
  margin-right: 150px;
  margin-left: 20px;
  @media (max-width: 1440px) {
    margin-right: 50px;
  }
  @media (max-width: 1180px) {
    margin: 0 auto;
    width: auto;
  }
}
.block-green {
  background: #006942;
  padding: 24px 79px 48px 79px;
  margin-left: 150px;
  position: relative;
  margin-bottom: 20px;
  z-index: 222;
  height: 250px;
  @media (max-width: 1440px) {
    margin-left: 50px;
    padding: 20px 50px 20px 50px;
    height: 270px;
  }
  @media (max-width: 1180px) {
    height: auto;
    margin: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.block-grey {
  background: #e7e7e7;
  padding: 24px 79px 47px 79px;
  position: relative;
  z-index: 222;
  margin-left: 150px;
  height: 285px;
  @media (max-width: 1440px) {
    margin-left: 50px;
    padding: 20px 50px 20px 50px;
    height: 330px;
  }
  @media (max-width: 1180px) {
    height: auto;
    margin: 0;
    margin-bottom: 20px;
  }
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}

.title-white {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  border-bottom: solid 1px #fff;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 16px;
  }
}
.title-black {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #252827;
  position: relative;
  z-index: 222;
  border-bottom: solid 1px #252827;
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media (max-width: 740px) {
    font-size: 16px;
  }
}
.text-white {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}

.text-black {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-img {
  position: absolute;
  right: 200px;
}
.macroPrudentialPolicies-content {
  display: flex;
  @media (max-width: 1180px) {
    flex-direction: column;
  }
  p {
    padding-bottom: 20px;
  }
}
</style>
