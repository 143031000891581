<template>
  <div class="gettingReminders">
    <div class="gettingReminders-wrapper">
      <div class="gettingReminders-content">
        <div>
          <div class="block">
            <h2>GETTING A REMINDER</h2>
            <p class="text">
              If you provided a valid email address, you will receive two
              reminders by email (to the address you provided). The first is on
              the day before the first day of your annual renewal filing month.
              You will receive a second reminder 5 days before the end of the
              filing month.
            </p>
            <p class="text">
              You will need to confirm any company details or update any
              changes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.gettingReminders-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 150px;
  @media (max-width: 1100px) {
    padding-top: 0px;
    padding-bottom: 35px;
    max-width: 1024px;
  }
}
.block {
  background: #fff;
  padding: 50px 50px 50px 0px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 0px;
    padding-bottom: 10px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  padding-left: 200px;
  @media (max-width: 1100px) {
    padding-left: 0px;
  }
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.block-container {
  position: relative;
}
.gettingReminders-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
</style>
