<template>
  <div class="listOfEntitle-container">
    <div class="listOfEntitle-tabs-container">
      <!-- Dynamically generate tabs -->
      <button
          v-for="(table, index) in tables"
          :key="index"
          :class="{ active: activeTab === index }"
          @click="activeTab = index"
      >
        {{ table.label }}
      </button>
    </div>

    <!-- Render only the active table -->
    <div class="listOfEntitle-table-container">
      <h2>{{ activeTable.label }}</h2>
      <div class="listOfEntitle-table-content">
        <!-- Table structure -->
        <table>
          <thead>
          <tr>
            <th>List of licensed companies</th>
            <th>Date of licensing. License number</th>
            <th>Web Pages</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in filteredData(activeTable.data)" :key="index">
            <td>{{ row.date }}</td>
            <td>{{ row.name }}</td>
            <td>
              <a v-if="row.website" :href="row.website" target="_blank">Web Page</a>
              <span v-else></span>
            </td>
            <td>{{ row.status }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 0, // Default active tab index
      tables: [
        {
          label: "Banking Licenses",
          data: [
            {
              name: "Alexandria Bancorp Ltd",
              date: "12.10.2021 N164 21/AABL",
              website: "https://alexandriainterbu.com/",
              status: "operating",
            },
            {
              name: "AngloAmerican International Bancorp",
              date: "02.11.2020 N 161 20/AABL",
              website: "https://aabancorp.com/",
              status: "Operating",
            },
            {
              name: "Axis Capital Bank",
              date: "29.07.2024 N 213 24/ACBC",
              status: "operating",
            },
            {
              name: "Cajamar Ltd",
              date: "17.05.2018, N54 18/ CJBL",
              status: "operating",
            },
            {
              name: "Cleveland IBU Ltd",
              date: "19.02.2020, N159 20/ CIBL",
              website: "https://clevelandibu.com/",
              status: "operating",
            },
            {
              name: "First Delta East bancorp",
              date: "01.06.2019, N141 19/ FDBL",
              website: "https://www.fdeban.company/",
              status: "operating",
            },
            {
              name: "Global Trust Banking",
              date: "01.05.2024 N 196 24/GTBC",
              status: "operating",
            },
            {
              name: "Ibercaja International De Africa Ltd",
              date: "22.11.2017, N23 17/ IIBL",
              website: "",
              status: "operating",
            },
            {
              name: "Interbanco International Ltd",
              date: "12.03.2019, N123 19/ IIBL",
              website: "",
              status: "operating",
            },
            {
              name: "MIB BANQUE Ltd",
              date: "09.01.2020, N154 20/ MIBL",
              website: "https://www.mibbanque.org/",
              status: "operating",
            },
            {
              name: "MetroPrime Bank",
              date: "13.03.2024 N 189 24/MPBL",
              website: "",
              status: "operating",
            },
            {
              name: "New River Bank Ltd (NRB LTD)",
              date: "05.03.2021 N 165 21/ NRBL",
              website: "https://new-riverbank.com/",
              status: "operating",
            },
            {
              name: "Türkiye Uluslararasi Yatirim Bankasi",
              date: "04.01.2022 N 168 22/TUBL",
              website: "https://turkibu.com/",
              status: "operating",
            },
            {
              name: "Unicaja Limited",
              date: "10.12.2018, N72 18/ UCBL",
              website: "",
              status: "operating",
            },
            {
              name: "Unity Financial Group",
              date: "20.06.2024 N 206 24/UFGC",
              website: "",
              status: "operating",
            }
          ],
        },
        {
          label: "Brokerage Companies",
          data: [
            {
              name: "Brokerage House Limited",
              date: "28.10.2021 N 033 20/BHBRL",
              status: "operating",
            },
            {
              name: "Delta Trade Services",
              date: "11.05.2024 N 197 78/DTSL",
              status: "operating",
            },
            {
              name: "General Trade Group Ltd",
              date: "22.05.2020 N 81 20/GTBRL",
              status: "operating",
            },
            {
              name: "Horizon Investment Corp",
              date: "14.04.2024 N 193 78/HICB",
              status: "operating",
            },
            {
              name: "Quantum Markets Ltd",
              date: "27.02.2024 N 186 78/QMLB",
              status: "operating",
            },
            {
              name: "SilverLine Brokers",
              date: "16.01.2024 N 184 78/SLBB",
              status: "operating",
            },
            {
              name: "Techno Brokerage Corporation",
              date: "09.08.2021 N 145 20/TBBRL",
              status: "operating",
            },
            {
              name: "Trade&Save Ltd",
              date: "12.12.2019 N 44 19/TSBRL",
              website: "https://tradesavecompany.com/",
              status: "operating",
            },
            {
              name: "Trading Strategy Corp",
              date: "03.02.2020 N 064  20/TCBRL",
              website: "",
              status: "operating",
            },
            {
              name: "World Brokers Limited",
              date: "18.11.2019 N 078 19/WBBRL",
              website: "http://worldbrokersltd.com/",
              status: "operating",
            },
          ],
        },

        {
          label: "Insurance companies",
          data: [
            {
              name: "Apex Cover Ltd",
              date: "30.06.2024 N 208 67/ACLI",
              status: "operating",
            },
            {
              name: "Euro Insurance Corp",
              date: "30.07.2020 N 114 20/EIIL",
              status: "operating",
            },
            {
              name: "First Asian Company Ltd",
              date: "24.04.2020 N 050 20/FAIL",
              status: "operating",
            },
            {
              name: "Guardian Risk Associates",
              date: "24.08.2024 N 216 67/GRAI",
              status: "operating",
            },
            {
              name: "Insurance Solutions Limited",
              date: "15.10.2019 N 105 19/ISIL",
              status: "operating",
            },
            {
              name: "Safe Life Corporation Ltd",
              date: "08.06.2021 N 169 21/SLIL",
              status: "operating",
            },
            {
              name: "Shield Financial Corp",
              date: "05.10.2024 N 221 67/SFCL",
              status: "operating",
            },
            {
              name: "Unity Safe Insurance",
              date: "12.07.2024 N 209 67/USIL",
              status: "operating",
            },
            {
              name: "West Side Group Ltd",
              date: "26.03.2021 N 132 21/WSIL",
              status: "operating",
            }
          ],
        },

        {
          label: "Trust companies",
          data: [
            {
              name: "Certitude Trust Services",
              date: "10.06.2024 N 207 34/CTSL",
              website: "",
              status: "operating",
            },
            {
              name: "Foundation Trust LLC",
              date: "25.09.2024 N 220 34/FTLL",
              status: "operating",
            },
            {
              name: "General Fund Corporation",
              date: "12.07.2021 N 146 21/GFTL",
              website: "",
              status: "operating",
            },
            {
              name: "Global Trust Services Ltd",
              date: "20.12.2019 N 029 19/GRTL",
              website: "",
              status: "operating",
            },
            {
              name: "International Trust Corp",
              date: "16.03.2020 N 87 20/ITTL",
              status: "operating",
            },
            {
              name: "Public Trust Association",
              date: "04.06.2020 N 109 20/PTTL",
              status: "operating",
            },
            {
              name: "Reliance Trust Agency",
              date: "17.07.2024 N 212 34/RTAC",
              status: "operating",
            },
            {
              name: "Secure Path Trust Co.",
              date: "08.08.2024 N 215 34/SPTC",
              status: "operating",
            },
            {
              name: "Western Sahara Management and Advisory Corporation",
              date: "01.05.2018 N 000001/WSTL",
              website: "https://advisorycorpws.com/index.html",
              status: "operating",
            },
            {
              name: "World Capital Ltd",
              date: "13.04.2021 N 129 21/WCTL",
              status: "operating",
            },
            {
              name: "",
              date: " ",
              website: "",
              status: "operating",
            },
            {
              name: "",
              date: " ",
              website: "",
              status: "operating",
            },
          ],
        },

        {
          label: "Gambling companies",
          data: [
            {
              name: "BetZenith Solutions",
              date: "03.03.2024 N 190 86/BZSG",
              status: "operating",
            },
            {
              name: "Electronic Games Limited",
              date: "10.08.2020 N 038 20/EGGL",
              status: "operating",
            },
            {
              name: "First Class Casino Ltd",
              date: "12.10.2021 N 083 21/FCGL",
              status: "operating",
            },
            {
              name: "Gaming Development Corporation",
              date: "International Gaming Center Corp",
              website: "14.12.2021 N 172 21/IGGL",
              status: "operating",
            },
            {
              name: "Jackpot Capital Gaming",
              date: "15.02.2024 N 188 86/JCGG",
              status: "operating",
            },
            {
              name: "Lucky Strike Play",
              date: "12.04.2024 N 195 86/LSPG",
              status: "operating",
            },
            {
              name: "One Game Ltd",
              date: "28.10.2019 N 118 19/OGGL",
              status: "operating",
            },
            {
              name: "Vegas Vertex Casino",
              date: "28.01.2024 N 186 86/VVCG",
              status: "operating",
            },
            {
              name: "Temminck Limited",
              date: "02.10.2024 N 021 01/TLGL",
              status: "operating",
            }
          ],
        },

        {
          label: "Crypto License",
          data: [
            {
              name: "BlockVault Finance",
              date: "19.03.2024 N 191 28/BVFB",
              status: "operating",
            },
            {
              name: "Crypto Systems Corporation",
              date: "03.03.2020 N 099 20/CSCL",
              status: "operating",
            },
            {
              name: "Crypto&Co Group",
              date: "04.08.2020 N 136 20/CGCL",
              status: "operating",
            },
            {
              name: "CryptoSphere Trading",
              date: "07.04.2024 N 194 28/CSTB",
              status: "operating",
            },
            {
              name: "Finance Bank Ltd",
              date: "15.11.2021 N 015 21/FBCL",
              status: "operating",
            },
            {
              name: "Financial Solution Corp",
              date: "24.05.2021 N 069 21/FSCL",
              status: "operating",
            },
            {
              name: "Ledger Logic Labs",
              date: "23.05.2024 N 198 28/LLLB",
              status: "operating",
            },
            {
              name: "Public Technologies Ltd",
              date: "27.11.2019 N 101 19/PTCL",
              status: "operating",
            },
            {
              name: "Quantum Coin Corp",
              date: "02.06.2024 N 200 28/QCCB",
              status: "operating",
            },
            {
              name: "Secure Transaction Limited",
              date: "26.02.2021 N 006 21/STCL",
              status: "operating",
            }
          ],
        },

      ],
    };
  },
  computed: {
    activeTable() {
      return this.tables[this.activeTab];
    },
  },
  methods: {
    filteredData(data) {
      return data.filter(row => row.name && row.date && row.status);
    },
  },
};
</script>

<style>
.listOfEntitle-tabs-container {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: .5rem;
  border: 4px double #af8e6f;
  padding: .5rem;
  @media (min-width: 1200px) {
    align-content: flex-start;
    justify-content: flex-start;
  }
}

.listOfEntitle-tabs-container button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #D7C7B7;
  margin-right: 10px;
  min-width: 200px;
  font-size: 16px;
  @media (min-width: 1200px) {
    min-width: 160px;
  }
}

.listOfEntitle-tabs-container button.active {
  background-color: #af8e6f;
  color: white;
}

.listOfEntitle-tabs-container button:hover {
  background-color: #af8e6f;
  color: white;
}

.listOfEntitle-container {
  max-width: 1200px;
  margin: auto;
  padding-top: 120px;
  padding-bottom: 150px;
  margin-inline: auto;
  padding-inline: 1rem;
}

h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #006942;
  position: relative;
  z-index: 222;
  margin: 0;
  padding-bottom: 56px;
  padding-left: 10px;
  @media (max-width: 850px) {
    padding-left: 20px;
    font-size: 24px;
    padding-bottom: 40px;
  }
}

.listOfEntitle-table-container {
  padding-top: 2rem;
}

.listOfEntitle-table-content {
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
}

th {
  text-align: inherit;
  line-height: 23px;
  font-family: "NotoSans-Regular" !important;
  font-style: normal !important;
  background: #af8e6f !important;
  color: #FBFBFB;
  cursor: pointer;
  border-bottom: 1px solid #EBEEF5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

td {
  font-family: "NotoSans-Regular" !important;
  border-bottom: 1px solid #EBEEF5;
  padding: 12px 0;
}

tr:nth-child(odd) {
  background-color: #f9f9f9;
}

th,
td {
  min-width: 180px;
  padding: 10px 20px;
  border-bottom: 1px solid #EBEEF5;
}

</style>
