<template>
  <div class="beAware">
    <div class="beAware-wrapper">
      <div class="beAware-content">
        <div class="sectionOne">
          <div class="block-grey">
            <p class="text-black">
              Advertisements, whether online or in the press, often appear for
              non-license-holders or non-regulated products. Protect yourself by
              checking who you are dealing with and whether the service or
              product is regulated. The CRA of Western Sahara regulates the
              providers of financial products and services, but (with the
              exception of some collective investment and retirement benefit
              schemes) it does not regulate products.
            </p>
          </div>
          <div class="block-green">
            <p class="text-white">
              If you think someone is seeking to offer you financial products or
              services without a suitable license, or outside the terms of their
              license, please contact us:
              <br />
              <br />
              <a href="mailto:info@crasadr.com" class="mail-link"
                ><b>Email: info@crasadr.com</b></a
              >
              <br />
              <br />
              We will be able to give you guidance on whether the person should
              be licensed by us or another body, or whether the financial
              product or service needs licenses.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.beAware-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 35px;
  @media (max-width: 1130px) {
    padding-top: 0px;
    max-width: 1024px;
  }
}
.sectionOne {
  width: 100%;
  display: flex;
  @media (max-width: 1130px) {
    flex-direction: column;
  }
}
.block-green {
  background: #006942;
  padding: 48px 79px 48px 79px;
  margin-left: 23px;
  position: relative;
  margin-bottom: 20px;
  margin-right: 200px;
  z-index: 222;
  height: 350px;
  @media (max-width: 1290px) {
    margin-right: 100px;
  }
  @media (max-width: 1130px) {
    height: auto;
    margin-right: 0px;
    margin-left: 0px;
    padding: 20px 20px 20px 20px;
  }
}
.block-grey {
  background: #e7e7e7;
  padding: 48px 100px 47px 79px;
  position: relative;
  z-index: 222;
  height: 350px;
  @media (max-width: 1290px) {
    padding: 48px 50px 47px 79px;
  }
  @media (max-width: 1130px) {
    height: auto;
    margin-bottom: 15px;
    padding: 20px 20px 20px 20px;
  }
}
.block-white {
  background: #fff;
  padding: 24px 200px 48px 79px;
  position: relative;
  z-index: 222;
  margin-bottom: 20px;
  height: 250px;
  margin-left: 20px;
}

.text-white {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}

.mail-link {
  color: #fbfbfb;
  text-decoration: none;
}

.text-black {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  padding-left: 120px;
  @media (max-width: 1130px) {
    padding-left: 0px;
  }
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.beAware-content {
  p {
    padding-bottom: 20px;
  }
}
</style>
