import { render, staticRenderFns } from "./ourVisions.vue?vue&type=template&id=004d4bfc&scoped=true&"
import script from "./ourVisions.vue?vue&type=script&lang=js&"
export * from "./ourVisions.vue?vue&type=script&lang=js&"
import style0 from "./ourVisions.vue?vue&type=style&index=0&id=004d4bfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "004d4bfc",
  null
  
)

export default component.exports