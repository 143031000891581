<template>
  <div class="financialStability">
    <div class="financialStability-wrapper">
      <div class="financialStability-content">
        <div>
          <h2>FINANCIAL STABILITY</h2>
          <div class="block">
            <p class="text">
              The Central Reserve Authority of Western Sahara, in cooperation
              with other authorities, contributes to the maintenance of
              safeguarding financial stability in Western Sahara. The Central
              Reserve Authority of Western Sahara drafts and implements the
              macro-prudential policy, which aims to prevent risks that threaten
              financial stability and increase the sustainability of banks and
              other financial institutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.financialStability-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 50px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.financialStability-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
.financialStability-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
</style>
