<template>
  <div>
    <iframe
      src="//www.exchangerates.org.uk/widget/ER-LRTICKER.php?w=auto&amp;s=1&amp;mc=GBP&amp;mbg=FFFFFF&amp;bs=no&amp;bc=000044&amp;f=verdana&amp;fs=12px&amp;fc=000044&amp;lc=000044&amp;lhc=FE9A00&amp;vc=FE9A00&amp;vcu=008000&amp;vcd=FF0000&amp;"
      width="100%"
      height="30"
      frameborder="0"
      scrolling="no"
      marginwidth="0"
      marginheight="0"
      style="color: red"
    ></iframe>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
</style>