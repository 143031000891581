<template>
  <header class="header" ref="headerRef">
    <nav class="nav container">

      <div class="nav__data">
        <router-link to="/" class="nav__logo" @click.native="scrollToTopAndCloseMenu">
          <img src="../assets/logo.png" class="header-logo-img" alt="header logo" />
        </router-link>

        <div class="nav__toggle" @click="toggleMenu">
          ☰
        </div>
      </div>

      <div :class="['nav__menu', { 'show-menu': menuOpen }]" id="nav-menu">
        <ul class="nav__list">
          <li>
            <router-link to="/" class="nav__link" @click.native="handleLinkClick">Home</router-link>
          </li>

          <li v-for="item in menuItems" :key="item.key" :class="['dropdown__item', { 'show-dropdown': openDropdown === item.key }]">
            <div
                class="nav__link dropdown__button"
                @click="toggleDropdown(item.key)"
                role="button"
                tabindex="0"
                @keypress="toggleDropdown(item.key)"
            >
              {{ item.label }}
              <i v-if="openDropdown === item.key" class="el-icon-arrow-up el-icon--right"></i>
              <i v-else class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <div :class="['dropdown__container', { 'dropdown-open': openDropdown === item.key }]">
              <div  class="dropdown__group" v-for="group in item.groups" :key="group.title">
                <ul  class="dropdown__list">
                  <li v-for="link in group.links" :key="link.label">
                    <router-link :to="link.route" class="dropdown__link" @click.native="handleLinkClick">{{ link.label }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <li>
            <router-link to="/RegisteredAgents" class="nav__link" @click.native="handleLinkClick">Registered agents</router-link>
          </li>

          <li>
            <router-link to="/ListOfEntities" class="nav__link" @click.native="handleLinkClick">List of Entities</router-link>
          </li>

          <li>
            <router-link to="/Contact" class="nav__link" @click.native="handleLinkClick">Contact us</router-link>
          </li>

        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      menuOpen: false,
      openDropdown: null,
      isToggleClicked: false,
      menuItems: [
        {
          key: "About us",
          label: "About us",
          groups: [
            {
              links: [
                { label: "What we do", route: "/WhatWeDo" },
                { label: "Institutional framework", route: "/InstitutionalFramework" },
              ],
            },
          ],
        },
        {
          key: "Our services & Fees",
          label: "Our services & Fees",
          groups: [
            {
              links: [
                { label: "Bank License", route: "/BankLicense" },
                { label: "Brokerage companies", route: "/BrokerageCompanies" },
                { label: "Insurance companies", route: "/InsuranceCompanies" },
                { label: "Trust companies", route: "/TrustCompanies" },
                { label: "Gambling companies", route: "/GamblingCompanies" },
                { label: "Crypto License", route: "/CryptoLicense" },
                { label: "Services & Fees", route: "/ServicesFees" },
                { label: "Business name info", route: "/BusinessNameInfo" },
                { label: "Application Form", route: "/ApplicationForm" }
              ],
            },
          ],
        },
        {
          key: "Company supervision",
          label: "Company supervision",
          groups: [
            {
              links: [
                { label: "Community company resources", route: "/CommunityCompanyResources" }
              ],
            },
          ],
        },
        {
          key: "Financial stability",
          label: "Financial stability",
          groups: [
            {
              links: [
                { label: "Financial stability", route: "/FinancialStability" },
                { label: "Macro-prudential policies", route: "/MacroPrudentialPolicies" },
                { label: "Macro-prudential Strategy", route: "/MacroPrudentialStrategy" }
              ],
            },
          ],
        },
        {
          key: "Resolution",
          label: "Resolution",
          groups: [
            {
              links: [
                { label: "Resolution", route: "/Resolution" },
                { label: "Government securities", route: "/GovernmentSecurities" },
                { label: "Payment system", route: "/PaymentSystem" }
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.isToggleClicked = true;
      this.menuOpen = !this.menuOpen;
    },
    toggleDropdown(key) {
      this.openDropdown = this.openDropdown === key ? null : key;
    },
    handleLinkClick() {
      console.log('Link clicked'); // Debugging log
      this.scrollToTopAndCloseMenu();
    },
    scrollToTopAndCloseMenu() {
      console.log('Closing menu and scrolling to top'); // Debugging log
      window.scrollTo(0, 0);
      this.closeDropdownsAndMenu();
    },
    closeDropdownsAndMenu() {
      console.log('Closing dropdowns and menu'); // Debugging log
      this.openDropdown = null; // Close all dropdowns
      this.menuOpen = false; // Close the main menu
    },
    handleClickOutside(event) {
      if (this.isToggleClicked) {
        this.isToggleClicked = false;
        return;
      }
      if (this.$refs.headerRef && !this.$refs.headerRef.contains(event.target)) {
        this.closeDropdownsAndMenu();
      }
    },
    handleResize() {
      if (window.innerWidth >= 1118) {
        this.menuOpen = false;
        this.openDropdown = null;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
/*=============== HEADER ===============*/

:root {
  --header-height: 4.5rem;

  --gray-alt-dim: #E6E6E6;

  --small-font-size: .813rem;

  /* Font weight */
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-semi: 700;
}

@media screen and (min-width: 1118px) {
  :root {
    --big-font-size: 4rem;
    --biggest-font-size: 5rem;
    --h1-font-size: 2rem;
    --h2-font-size: 1.60rem;
    --h3-font-size: 1.10rem;

    --small-font-size: 1rem;
  }
}

ul {
  list-style: none;
}

.container {
  max-width: 1400px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 8px hsla(220, 68%, 12%, 0.1);
  background-color: white;
  z-index: 1000;
}

/*=============== NAV ===============*/
.nav {
  padding-inline: 1.5rem;
  position: relative;

}
.nav__data {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav__logo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  font-weight: var(--font-semi-bold);
  font-size: var(--h3-font-size);
  transition: color 0.3s;
}
.nav__logo .header-logo-img {
  width: 70px;
}

.nav__toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 32px;
  height: 32px;
  z-index: 1000;
}

@media screen and (max-width: 1230px) {
  .header {
    padding-block: .5rem;
  }

  .nav__menu {
    background-color: white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: auto;
    padding-block: 1.5rem;
    pointer-events: none;
    opacity: 0;
    transition: top 0.4s, opacity 0.3s;
  }
  .nav__menu::-webkit-scrollbar {
    width: 0.5rem;
  }
  .nav__menu::-webkit-scrollbar-thumb {
    background-color: hsl(220, 12%, 70%);
  }
}

.nav__list {
  overflow-y: hidden;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.nav__link {
  color: black;
  font-weight: var(--font-semi-bold);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--h3-font-size);
  text-decoration: none;
  cursor: pointer;
  transition: color .3s ease-in-out;
}
.nav__link:hover {
  background-color: var(--gray-alt-dim);
  color: #A68769;
}

/* Show menu */
.show-menu {
  opacity: 1;
  pointer-events: initial;
}

/*=============== DROPDOWN ===============*/
.dropdown__button {
  cursor: pointer;
}
.dropdown__arrow {
  width: 1rem;
  height: 1rem;
  font-weight: initial;
  transition: transform 0.4s;
}
.dropdown__content, .dropdown__group, .dropdown__list {
  display: grid;
}
.dropdown__container {
  height: 0;
  overflow: hidden;
  transition: height 0.4s;
}
.dropdown-open {
  height: auto; /* Ensure it expands properly */
}
.dropdown__content {
  row-gap: 1.75rem;
}
.dropdown__group {
  row-gap: 0.5rem;
}
.dropdown__group:first-child {
  margin-top: 1.25rem;
}
.dropdown__group:last-child {
  margin-bottom: 1.25rem;
}

.dropdown__title {
  font-size: var(--small-font-size);
  font-weight: var(--font-semi);
}
.dropdown__list {
  row-gap: 0.50rem;
  padding-left: .7rem;
}
.dropdown__link {
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  text-decoration: none;
  color: black;
  transition: color 0.3s;
}

.dropdown__link:hover {
  color: #A68769;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (min-width: 1230px) {
  /* Nav */
  .nav {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav__logo .header-logo-img {
    width: 100px;
  }

  .nav__toggle {
    display: none;
  }
  .nav__list {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
  .nav li {
    display: flex;
  }
  .nav__link {
    padding: 0;
  }
  .nav__link:hover {
    background-color: initial;
  }

  .dropdown__button {
    column-gap: 0.25rem;
    pointer-events: all;
  }
  .dropdown__container {
    padding-left: 1rem;
    width: 250px;
    height: max-content;
    position: absolute;
    background-color: white;
    box-shadow: 0 6px 8px hsla(220, 68%, 12%, 0.05);
    pointer-events: none;
    opacity: 0;

    transition: top 0.4s, opacity 0.3s;
    
  }
  .dropdown__group {
    padding: 2rem 0;
    align-content: baseline;
    row-gap: 1.50rem;

  }
  .dropdown__group:first-child, .dropdown__group:last-child {
    margin: 0;
  }
  .dropdown__list {
    row-gap: 1rem;
  }

  .dropdown__item {
    cursor: pointer;
  }

  .dropdown__item > .dropdown__container {
    opacity: 0; /* Set opacity to 0 initially */
  }
  .show-dropdown > .dropdown__container {
    top: 6.3rem;
    opacity: 1;
    pointer-events: initial;
    cursor: initial;
  }
}

@media screen and (min-width: 1400px) {
  .dropdown__link {
    font-size: var(--small-font-size);
  }
}
</style>
