<template>
  <div class="national-holidays fees-serv">
    <div class="national-holidays-wrapper">
      <div class="national-holidays-content">
        <div>
          <h2>SERVICES &amp; PRICES</h2>
          <div class="block">
            <el-table :data="tableData" style="width: 100%; text-align: center;" 
            :span-method="arraySpanMethod" :cell-style="centerAlignCellStyle" 
            :row-class-name="tableRowClassName"
            class="center-aligned-table el-table--scrollable-x">
              <el-table-column
                prop="type"
                label="Type of services"
                width="426px"
                :formatter="titleFormatter"
              >
              </el-table-column>
              <el-table-column
                prop="service"
                label="Services cost"
                width="326px"
                :cell-style="{ textAlign: 'center' }"
              >
              </el-table-column>
              <el-table-column
                prop="applicationFee"
                label="Application fee"
                width="418px"
                :cell-style="{ textAlign: 'center' }"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [
        { type: "Company formation", service: "", applicationFee: "", isTitle: true },
        { type: "IBC", service: "540.00 USD", applicationFee: "240.00 USD", isTitle: false },
        { type: "Banking License", service: "", applicationFee: "", isTitle: true },
        { type: "Banking License A (I) Class General Bankning license", service: "18,000.00 USD", applicationFee: "1,200.00 USD", isTitle: false },
        { type: "Banking License B (II) Class Restricted Bankning license", service: "13,900.00 USD", applicationFee: "1,200.00 USD", isTitle: false },
        { type: "Trust Company License", service: "", applicationFee: "", isTitle: true },
        { type: "General License", service: "12,000.00 USD", applicationFee: "720.00 USD", isTitle: false },
        { type: "Insurance License", service: "", applicationFee: "", isTitle: true },
        { type: "Insurance License I & II Class General Insurance license", service: "7,200.00 USD", applicationFee: "540.00 USD", isTitle: false },
        { type: "Insurance License III Class Restricted Insurance license", service: "5,200.00 USD", applicationFee: "540.00 USD", isTitle: false },
        { type: "eGambling License", service: "", applicationFee: "", isTitle: true },
        { type: "eGambling License I Category Authorizes the effect of the gambling transaction", service: "12,000.00 USD", applicationFee: "720.00 USD", isTitle: false },
        { type: "eGambling License II Category Authorizes the organization and preparation of gambling operations", service: "10,800.00 USD", applicationFee: "720.00 USD", isTitle: false },
        { type: "Brokerage License", service: "", applicationFee: "", isTitle: true },
        { type: "Brokerage License", service: "12,000.00 USD", applicationFee: "720.00 USD", isTitle: false },
        { type: "Crypto License", service: "", applicationFee: "", isTitle: true },
        { type: "Crypto License", service: "7,200.00 USD", applicationFee: "540.00 USD", isTitle: false },
        { type: "Asset management license", service: "", applicationFee: "", isTitle: true },
        { type: "Asset management and capital management license", service: "10,800.00 USD", applicationFee: "720.00 USD", isTitle: false },
      ],
    };
  },
  methods: {
    arraySpanMethod({ row, columnIndex }) {
      if (row.isTitle) {
        if (columnIndex === 0) {
          return [1, 3]; 
        } else {
          return [0, 0]; 
        }
      }
      return [1, 1];
    },
    titleFormatter(row, column, cellValue) {
      if (row.isTitle) {
        return row.type;
      }
      return cellValue;
    },
    centerAlignCellStyle() {
      return {
        textAlign: 'center',
      };
    },
    tableRowClassName({ row }) {
      if (row.isTitle) {
        return 'highlighted';
      }
      return 'word-break';
    }
  }
};
</script>

<style>
.highlighted {
  background-color: #E7E7E7!important;
  font-style: italic;
  font-weight: 700;
}
.word-break div {
  word-break: unset!important;
}

.fees-serv .el-table__header, .fees-serv .el-table__cell {
  text-align: center!important;
}
</style>

<style lang="scss" scoped>
.national-holidays-wrapper {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 150px;
  // height: 500px;
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #006942;
  position: relative;
  z-index: 222;
  margin: 0;
  padding-bottom: 56px;
  padding-left: 80px;
    @media (max-width: 850px) {
    padding-left: 20px;
    font-size: 24px;
    padding-bottom: 40px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
}
.el-table td,
.el-table th.is-leaf {
  background: red;
}

.el-table__cell cell{
  text-align: center!important;
}

.fees {
  text-align: right;
}
.national-holidays .cell {
  text-align: center;
}
</style>
