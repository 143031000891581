<template>
  <div class="main-wrapper">
    <Exchange />
    <BrokerageCompanies />
    <InsuranceAndBrokerage />
    <Activities />
    <ApplicationForm />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import BrokerageCompanies from "./brokerageCompanie.vue";
import InsuranceAndBrokerage from "./insuranceAndBrokerages.vue";
import Activities from "./activitie.vue";
import ApplicationForm from "./applicationForms.vue";

export default {
  components: {
    Exchange,
    BrokerageCompanies,
    InsuranceAndBrokerage,
    Activities,
    ApplicationForm,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
