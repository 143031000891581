<template>
  <div class="regulatory-law">
    <div class="regulatory-law-wrapper">
      <div class="regulatory-law-content">
        <div>
          <h2>REGULATORY LAWS</h2>
          <div class="block">
            <div>
              <a
                download="companies-regulations-2018.pdf"
                href="companies-regulations-2018.pdf"
              >
                <p class="text">
                  <img src="..\..\..\assets\about\pdf.svg" alt="" />
                  The Companies Regulations Act, 2018
                </p>
              </a>
              <a
                download="The_Banking_and_Trust_Act_2017.pdf"
                href="The_Banking_and_Trust_Act_2017.pdf"
              >
                <p class="text">
                  <img src="..\..\..\assets\about\pdf.svg" alt="" />
                  The Banking and Trust Act, 2017;
                </p>
              </a>
            </div>
            <div style="margin-right: 50px">
              <a
                download="The-Insurance-and-Brokerage-act-2017.pdf"
                href="The-Insurance-and-Brokerage-act-2017.pdf"
              >
                <p class="text">
                  <img src="..\..\..\assets\about\pdf.svg" alt="" />
                  The Insurance and Brokerage Act, 2017
                </p>
              </a>
              <a
                download="aml-cft-guidelines.pdf"
                href="aml-cft-guidelines.pdf"
              >
                <p class="text">
                  <img src="..\..\..\assets\about\pdf.svg" alt="" />
                  Money Laundering Regulations, 2017;
                </p>
              </a>
            </div>
            <div>
              <a
                  download="The-Crypto-Company-Act-2021.pdf"
                  href="The-Crypto-Company-Act-2021.pdf"
              >
                <p class="text">
                  <img src="..\..\..\assets\about\pdf.svg" alt="" />
                  The Crypto Company Act 2020
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.regulatory-law-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 150px;
  // height: 500px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    padding-bottom: 50px;
  }
}
.block {
  background: #fff;
  padding: 50px 50px 50px 200px;
  display: flex;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
  @media (max-width: 540px) {
    flex-direction: column;
  }
  div {
    &:first-child {
      margin-right: 50px;
      @media (max-width: 540px) {
        margin-right: 0;
      }
    }
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-bottom: 20px;
    margin: 0;
  }
  @media (max-width: 740px) {
    font-size: 24px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -85%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #006942;
  display: flex;
  align-items: center;
  @media (max-width: 740px) {
    font-size: 14px;
  }
  img {
    padding-right: 20px;
  }
}
.regulatory-law-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
.regulatory-law-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
a {
  text-decoration: none;
}
</style>
