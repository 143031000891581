<template>
  <div class="crypto-license">
    <div class="crypto-license-wrapper">
      <div class="crypto-license-content">
        <div>
          <h2>CRYPTO LICENSE</h2>
          <div class="block">
            <p class="text">
              A new type of service provided by the Central Reserve Authority of
              Western Sahara, SADR is a license for virtual currency activities
              including the followings:
            </p>
            <div>
              <span class="text"
                >Crypto currency exchange operator <br />
                a company or an affiliate of another company exchanging
                cryptocurrency owned by the client for the commission fee.</span
              >
              <span class="text"
                >Crypto currency depository wallet operator<br />a company or an
                affiliate of another company managing client cryptocurrency
                depository wallets.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.crypto-license-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.text-bold {
  font-weight: bold;
}

.no-line {
  &::before {
    display: none;
  }
}
.crypto-license-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
.crypto-license-text {
  font-family: "ProximaNovaCond-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
  color: #444444;
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -90%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
span {
  display: block;
  padding-bottom: 16px;
  margin-left: 40px;
  position: relative;
  &::before {
    content: "";
    width: 20px;
    height: 2px;
    background: #006942;
    position: absolute;
    top: 11px;
    left: -38px;
  }
}
</style>
