<template>
  <div class="contacts">
    <div class="contacts-wrapper">
      <div class="contacts-content">
        <div>
          <h2>CONTACT US</h2>
          <div class="block">
            <div class="contacts-item">
              <img src="../../assets/contact/icon-map.svg" />
              <p class="text">
                SRFRB, Tifariti, Sahrawi Arab Democratic Republic<br />2390835
                Tifariti<br />WESTERN SAHARA
              </p>
            </div>
            <div class="contacts-item">
              <img src="../../assets/contact/icon-message.svg" />
              <p class="text">info@crasadr.com<br />anylicense@crasadr.com</p>
            </div>
            <p class="text">
              For all questions contact to licensed representative –
              <a href="https://advisorycorpws.com/" target="_blank"
                >Western Sahara Management and Advisory Corporation</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.contacts {
  min-height: 59.7vh;
}
.contacts-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.text-bold {
  font-weight: bold;
}

img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.contacts-item {
  display: flex;
  margin: 30px 0;
}

.no-line {
  &::before {
    display: none;
  }
}
.contacts-content {
  display: flex;
  width: 80%;
  justify-content: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: #006942;
    position: absolute;
    left: -85%;
    @media (max-width: 1024px) {
      display: none;
    }
  }
}
</style>
