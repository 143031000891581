<template>
  <div class="businessNameBaner">
    <div class="businessNameBaner-wrapper">
      <div class="businessNameBaner-content">
        <div>
          <div class="block">
            <h2>WHY HAVE BUSINESS NAMES?</h2>
            <div class="block-indent">
              <p class="text">
                Business names are used for a number of different reasons.
                Business names are often used to help businesses inform the
                public about what they do or for marketing purposes. It is also
                often used by business operators that have more than one
                business and they wish to make it easier for people to
                differentiate between them.
              </p>
              <p class="text">
                Whether you wish to use a business name will depend on your
                individual circumstances.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.businessNameBaner-wrapper {
  // max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    padding-top: 0px;
    max-width: 1024px;
  }
}
.block {
  background: #006942;
  padding: 50px 0px 50px 300px;
  position: relative;
  z-index: 222;
  @media (max-width: 1110px) {
    padding: 20px 20px 20px 20px;
  }
}
.block-indent {
  padding-right: 200px;
  @media (max-width: 1110px) {
    padding-right: 0px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  width: 100%;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 51%;
    height: 2px;
    background: #fbfbfb;
    @media (max-width: 1452px) {
      width: 33%;
    }
    @media (max-width: 1177px) {
      width: 25%;
    }
    @media (max-width: 1100px) {
      display: none;
    }
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.businessNameBaner-content {
  display: flex;
  width: 80%;
  @media (max-width: 1110px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
</style>
