<template>
  <div class="blockWithPicture">
    <div class="blockWithPicture-wrapper">
      <div class="blockWithPicture-content">
        <div>
          <div class="block">
            <div class="block-indent">
              <p class="text">
                The CRA is responsible for the licensing, supervision, and
                regulation of financial service providers including
                international banks, trust companies, insurance companies,
                mutual funds, corporate service providers, company managers,
                international business companies, and money transmitter
                services.
              </p>
              <p class="text">
                The Central Reserve Authority of Western Sahara is also the sole
                supervisory authority of non-financial service providers,
                including law firms, real estate agents, car dealers, other
                sellers of high-value commodities and luxury goods e.g. jewelers
                and Non-Profit Organizations, and monitors compliance with their
                obligations in the anti-money laundering and countering the
                financing of terrorism legislation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.blockWithPicture {
  background: url("../../../assets/paymentSystem/payment-system.png");
  background-repeat: no-repeat;
  background-size: auto;
  height: 700px;
  @media (max-width: 1100px) {
    height: 550px;
  }
}
.blockWithPicture-wrapper {
  // max-width: 1024px;
  margin: 300px auto 0 auto;
  padding-top: 85px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    padding-top: 35px;
    max-width: 1024px;
    justify-content: center;
    margin: 0px auto 0 auto;
  }
}
.block {
  background: #af8e6f;
  padding: 90px 128px 90px 128px;
  position: relative;
  margin-right: 200px;
  position: relative;
  top: -260px;
  z-index: 222;
  width: 630px;
  @media (max-width: 1100px) {
    margin-right: 0;
    top: 0;
    padding: 20px 20px 20px 20px;
  }
  @media (max-width: 740px) {
    width: auto;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #fbfbfb;
  position: relative;
  z-index: 222;
  margin: 0;
  margin-bottom: 56px;
  @media (max-width: 740px) {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #fbfbfb;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
ul {
  margin: 0;
  padding: 0;
  margin-bottom: 46px;
  li {
    list-style-type: none;
    margin-bottom: 16px;
    margin-left: 40px;
    position: relative;
    &::before {
      content: "";
      width: 20px;
      height: 2px;
      background: #fbfbfb;
      position: absolute;
      top: 12px;
      left: -37px;
    }
  }
}
.blockWithPicture-content {
  p {
    padding-bottom: 20px;
  }
}
</style>
