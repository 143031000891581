<template>
  <div class="main-wrapper">
    <Exchange />
    <CommunityBanking />
    <BeAware />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import CommunityBanking from "./communityBanking";
import BeAware from "./beAware";

export default {
  components: {
    Exchange,
    CommunityBanking,
    BeAware,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
