<template>
  <div class="historys">
    <div class="historys-wrapper">
      <div class="historys-content">
        <div>
          <h2>HISTORY OF WESTERN SAHARA</h2>
          <div class="block">
            <p class="text">
              Western Sahara, previously a Spanish colony from 1884 until 1976, entered a period of contention following
              Spain's withdrawal. The territory, known for its historical ties to nomadic tribes, proclaimed its
              independence through the Polisario Front, a Sahrawi nationalist movement. This declaration marked the
              beginning of a prolonged conflict primarily with Morocco, which also claims sovereignty over the region.
            </p>
            <p class="text">
              The conflict saw initial hostilities with both Morocco and Mauritania. However, Mauritania renounced its
              claims in 1979, following a peace agreement with the Polisario Front. The dispute with Morocco persisted,
              focusing on efforts by the Polisario Front to establish sovereignty over what it terms as "liberated
              areas" while referring to Moroccan-controlled zones as occupied territories.
            </p>
            <p class="text">
              As of 2016, the recognition status of Western Sahara has been complex. At one point, it was recognized by
              85 UN member states, although 37 of these countries later withdrew their recognition. The United Nations
              itself does not recognize Western Sahara as a sovereign state, though it is a full member of the African
              Union (AU). In a significant move reflecting the contentious status of Western Sahara, Morocco withdrew
              from the AU in 1982, objecting to the AU's recognition of Western Sahara. Morocco only rejoined the AU in
              2017.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.historys-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  // height: 500px;
  @media (max-width: 850px) {
    padding-top: 35px;
  }
}

h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #006942;
  position: relative;
  z-index: 222;
  margin: 0;
  padding-bottom: 56px;
  padding-right: 20px;
  padding-left: 20px;
  @media (max-width: 740px) {
    font-size: 24px;
    padding-bottom: 20px;
  }
}

.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  padding-right: 20px;
  padding-left: 20px;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}

.block {
  p {
    padding-bottom: 20px;
  }
}
</style>
