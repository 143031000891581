<template>
  <div class="brokerageCompanie">
    <div class="brokerageCompanie-wrapper">
      <div class="brokerageCompanie-content">
        <div>
          <h2>OVERVIEW OF INTERNATIONAL INSURANCE</h2>
          <div class="block">
            <p class="text">
              An IBC intending to carry on international insurance business in
              Western Sahara is required to be licensed under the IBC Act.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLearnMode: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.brokerageCompanie-wrapper {
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;

  @media (max-width: 1100px) {
    padding-top: 35px;
    padding-bottom: 0px;
    max-width: 1024px;
  }
}
.block {
  padding: 50px 50px 0px 200px;
  position: relative;
  z-index: 222;
  @media (max-width: 1024px) {
    padding: 20px 20px 20px 20px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #006942;
  position: relative;
  z-index: 222;
  padding-left: 200px;
  margin: 0;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    margin: 0;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
  @media (max-width: 740px) {
    font-size: 14px;
  }
}
.text-bold {
  font-weight: bold;
}
.brokerageCompanie-content {
  display: flex;
  width: 80%;
  @media (max-width: 1024px) {
    width: 100%;
  }
  p {
    padding-bottom: 20px;
  }
}
</style>
