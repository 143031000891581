<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <el-backtop :bottom="128">
      <div class="back-top-button">
        <i class="el-icon-arrow-up" />
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Header from "./components/header";
import Footer from "./components/footer";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Lato-Black";
  src: url("./assets/fonts/lato/Lato-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-BlackItalic";
  src: url("./assets/fonts/lato/Lato-BlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Bold";
  src: url("./assets/fonts/lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-BoldItalic";
  src: url("./assets/fonts/lato/Lato-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Italic";
  src: url("./assets/fonts/lato/Lato-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Light";
  src: url("./assets/fonts/lato/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-LightItalic";
  src: url("./assets/fonts/lato/Lato-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Regular";
  src: url("./assets/fonts/lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Thin";
  src: url("./assets/fonts/lato/Lato-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-ThinItalic";
  src: url("./assets/fonts/lato/Lato-ThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSans-Bold";
  src: url("./assets/fonts/nato/NotoSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSans-BoldItalic";
  src: url("./assets/fonts/nato/NotoSans-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSans-Italic";
  src: url("./assets/fonts/nato/NotoSans-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "NotoSans-Regular";
  src: url("./assets/fonts/nato/NotoSans-Regular.ttf") format("truetype");
}
body {
  background: #f6f6f6;
}
body,
p,
div {
  margin: 0;
}
.mail-footer {
  .el-input__inner {
    background: #015f3c !important;
    border: none !important;
    color: #fff;
  }
}

.search-bar {
  .el-input__inner {
    background: #2d2d2d !important;
    border: none !important;
    border-radius: 0 !important;
    color: #fff;
    height: 100%;
    font-size: 24px;
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: normal;
    padding: 0 30px;
  }
}
.nav-mobile__block .el-submenu__title {
  color: #fbfbfb !important;
}
.nav-mobile__blockTwo .el-submenu__title {
  color: #252827 !important;
}
.el-submenu__title i {
  display: none !important;
}
.block {
  .el-table td,
  .el-table th.is-leaf {
    padding: 19px 20px 19px 20px !important;
  }
}
.el-table th,
.el-table tr:nth-child(-1) {
  background: #af8e6f !important;
}
.el-table thead {
  font-family: "NotoSans-Regular" !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: #fbfbfb !important;
  text-align: center !important;
}
.el-table {
  font-family: "NotoSans-Regular" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 22px !important;
  align-items: center !important;
  color: #000000 !important;
}
.el-submenu__title:hover {
  background-color: transparent !important;
}
.el-submenu__title * {
  vertical-align: middle;
  cursor: pointer;
}
.el-menu-item-group__title {
  padding: 0px !important;
}
.form-block {
  .el-input__inner {
    -webkit-appearance: none;
    background-color: #2d2d2d;
    border: 1px solid #2d2d2d;
  }
  .el-input {
    width: 320px;
  }
  .el-input__inner {
    color: #fff;
  }
}
.el-backtop {
  z-index: 224 !important;
}
.registerSearch {
  @media (max-width: 740px) {
    .search-bar .el-input__inner {
      padding: 15px 30px;
    }
  }
}
</style>
