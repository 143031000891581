<template>
  <div class="main-wrapper">
    <Exchange />
    <InternationalGamblingLicense />
    <RequirementsForLicensing />
  </div>
</template>

<script>
import Exchange from "../../exchange-rates.vue";
import InternationalGamblingLicense from "./internationalGamblingLicense";
import RequirementsForLicensing from "./requirementsForLicensing";



export default {
  components: {
    Exchange,
    InternationalGamblingLicense,
    RequirementsForLicensing,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
