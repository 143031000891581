<template>
  <div class="main-wrapper">
    <RegisteredAgents />
  </div>
</template>

<script>
import RegisteredAgents from "./registeredAgents.vue";

export default {
  components: {
    RegisteredAgents,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  padding-top: 105px;
}
</style>
