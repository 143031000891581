<template>
  <div>
    <div class="fullscreen-bg">
      <div class="overlay">
        <div class="blur__block">
          <div class="blur__content">
            <h1>Welcome to Central Reserve Authority of Western Sahara</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.overlay h1 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  text-transform: uppercase;
  color: #fafafa;
  padding-right: 10px;
  padding-left: 10px;
  @media (max-width: 1024px) {
    font-size: 30px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
    line-height: 29px;
  }
}
.blur__block {
  width: 897px;
  height: 100%;
  left: 375px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    width: auto;
  }
  @media (max-width: 740px) {
    height: 80%;
  }
}

.fullscreen-bg {
  overflow: hidden;
  //   z-index: -100;
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 26%;
  @media (max-width: 1600px) {
    padding-top: 31%;
  }
  @media (max-width: 1250px) {
    padding-top: 41%;
  }
  @media (max-width: 1024px) {
    padding-top: 48%;
  }
  @media (max-width: 780px) {
    padding-top: 60%;
  }
  @media (max-width: 740px) {
    padding-top: 68%;
  }
    @media (max-width: 560px) {
    padding-top: 80%;
  }
  @media (max-width: 480px) {
    padding-top: 100%;
  }
}
.overlay {
  background: url("../../assets/main/mainBg.jpg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 501px;
  z-index: 4;
}

.content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.content h2 {
  text-align: center;
  font-size: 30px;
}

.content p {
  text-align: justify;
  font-size: 20px;
}

/**/
.backToSitehereBtn {
  display: block;
  width: 150px;
  height: 150px;
  background: #01bc78;
  text-align: center;
  line-height: 170px;
  font-size: 20px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  text-decoration: none;
  top: -40px;
  left: -40px;
  transform: rotate(-45deg) scale(1);
  transition: all 0.25s linear;
}

.backToSitehereBtn:hover {
  text-decoration: none;
  top: -10px;
  left: -10px;
  transform: rotate(-45deg) scale(1.3);
}
</style>
