<template>
  <div class="proposed-autonomy">
    <div class="proposed-autonomy-wrapper">
      <div class="proposed-autonomy-content">
        <div>
          <h2>PROPOSED AUTONOMY</h2>
          <div class="block">
            <div class="block-green">
              <p>2003</p>
              <p>
                In 2003, the UN ratified the Baker plan. The plan, created by
                James Baker and presented to the then-UN Secretary-General Kofi
                Annan, established a five-year transitional plan that would see
                the Western Sahara replaced by a Western Sahara Authority (WSA).
                The WSA would be a non- autonomous authority supervised by
                Morocco and a referendum on independence would be held. Morocco
                rejected the plan. Western Sahara is a full member of the
                African Union (AU, formerly the Organisation of African Unity,
                OAU). The Western Sahara is not a member of the Arab League, nor
                of the Arab Maghreb Union, both of which include Morocco as a
                full member.
              </p>
            </div>
            <div class="block-grey">
              <p>2017</p>
              <p>
                In 2017 in Western Sahara was founded Central Reserve Authority
                of Western Sahara to regulate and supervise the international
                financial services sector and the non-bank financial
                institutions in accordance with the various governing
                legislation and best international practices to ensure that
                these sectors are reputable and contribute to the economic and
                social development of Western Sahara.
              </p>
            </div>
            <div class="block-img">
              <img src="..\..\assets\proposedAutonomy\city.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.proposed-autonomy-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 85px;
  padding-bottom: 35px;
  // height: 500px;
  @media (max-width: 1150px) {
    max-width: 1024px;
  }
}
h2 {
  font-family: "Lato-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  color: #006942;
  position: relative;
  z-index: 222;
  margin: 0;
  padding-bottom: 56px;
  padding-left: 80px;
  @media (max-width: 850px) {
    padding-bottom: 40px;
    padding-left: 20px;
  }
  @media (max-width: 740px) {
    font-size: 24px;
  }
}
.text {
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #252827;
}
.block {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
  }
  &-green {
    background: #006942;
    margin-right: 20px;
    width: 33%;
    @media (max-width: 1150px) {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
    p:first-child {
      font-family: "Lato-Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      color: #fbfbfb;
      margin: 24px 48px 0px 48px;
      padding-bottom: 16px;
      border-bottom: solid 1px #fbfbfb;
      @media (max-width: 850px) {
        margin: 20px 20px 20px 20px;
      }
      @media (max-width: 740px) {
        font-size: 20px;
      }
    }
    p:last-child {
      font-family: "Lato-Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #fbfbfb;
      padding-left: 48px;
      padding-right: 48px;
      padding-top: 16px;
      @media (max-width: 850px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width: 740px) {
        font-size: 14px;
      }
    }
  }
  &-grey {
    background: #e7e7e7;
    margin-right: 20px;
    width: 33%;
    @media (max-width: 1150px) {
      width: 100%;
      margin-right: 0px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
    p:first-child {
      font-family: "Lato-Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      color: #252827;
      margin: 24px 48px 0px 48px;
      padding-bottom: 16px;
      border-bottom: solid 1px #252827;
      @media (max-width: 850px) {
        margin: 20px 20px 20px 20px;
      }
      @media (max-width: 740px) {
        font-size: 20px;
      }
    }
    p:last-child {
      font-family: "Lato-Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #252827;
      padding-left: 48px;
      padding-right: 48px;
      padding-top: 16px;
      @media (max-width: 850px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width: 740px) {
        font-size: 14px;
      }
    }
  }
  &-img {
    width: 33%;
    @media (max-width: 1150px) {
      width: 100%;
      text-align: center;
    }
  }
  img {
    @media (max-width: 480px) {
      width: 300px;
    }
  }
}
</style>
