<template>
  <div class="main-wrapper">
    <SearchMain />
    <Exchange />
    <FeesAndServices /> 
  </div>
</template>

<script>
import SearchMain from "./searchMain.vue";
import Exchange from "../../exchange-rates.vue";
import FeesAndServices from "./feesAndService.vue";

export default {
  components: {
    Exchange,
    SearchMain,
    FeesAndServices,
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
}
</style>
